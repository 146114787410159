import React, { Component } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { Route, Switch, Link, withRouter } from 'react-router-dom';

import Law from './Law';

const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Roboto', sans-serif;
		src: url('https://fonts.googleapis.com/css?family=Roboto');

	}

	html {
		box-sizing: border-box;
		${
      '' /* Setting the overall font size to px probhibits the users ability to zoom in or out. Therefore, the root font size should be a percentage so the
				user can zoom in or out on the page. If we set font-size to 100% it will be 16px, but we want 10px. So we go 10/16 = 62.50%.
		*/
    }
		font-size: 62.5%;

		${
      '' /* ORDER: Base + typography > general layout + grid > page layout > components */
    }

		@media (max-width: 56.25em) {
			font-size: 56.25%;
		}

		@media (max-width: 37.5em) {
			font-size: 50%;
		}

		@media (min-width: 112.5em) {
			font-size: 75%;
		}
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body {
		padding: 0;
		margin: 0;
		font-size: 1.5rem;
		background-color: #eef2f3 rgba(238, 242, 243, 0.2);
		line-height: 2;
    font-family: 'Roboto', sans-serif;
		font-weight: normal;
    font-style: normal;
    overflow: hidden;

		input, select, textarea {
			outline: none;
		}
	}

  p, h2 {
    color: black;
  }
	#root {
		height: 100vh;
	}
	a {
		text-decoration: none;
		color: #393939;
	}
`;

const LandingPageGrid = styled.div`
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 10vh 80vh 10vh;

  grid-template-areas:
    'header header header'
    'main main main'
    'footer footer footer';
`;

const Header = styled.div`
  grid-area: header;
  background: white;

  display: flex;
  padding: 0 5rem 0 1rem;
  justify-content: space-between;
  align-items: center;

  a,
  p,
  h2 {
    cursor: pointer;
    color: #333a42;
  }

  h2 {
    letter-spacing: 5px;
    font-weight: 600;
    margin-left: 5rem;
  }
`;

const Main = styled.div`
  grid-area: main;
  width: 100%;
  min-height: 100%;
  background: #f1f0ee;
  color: purple;
`;

const Footer = styled.div`
  grid-area: footer;
  background: #dbd8d3;
  display: flex;

  align-items: center;
  padding-left: 1rem;

  p {
    color: white;
  }
`;

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <LandingPageGrid>
          <Header>
            <Link to='/'>
              <h2>Lovfakta</h2>
            </Link>
            {this.props.location.pathname === '/lov-1' && (
              <div>
                <p>
                  Bekendtgørelse af lov om indkomstskat for personer m.v.
                  (personskatteloven)
                </p>
              </div>
            )}
            <select
              name='law-select'
              onChange={e => this.props.history.push(e.target.value)}
            >
              <option value=''> Vælg en lov </option>
              <option
                value='lov-1'
                onClick={() => this.props.history.push('/lov-1')}
              >
                Personskatteloven
              </option>
              <option value='lov-2'>Lov 2</option>
              <option value='lov-3'>Lov 3</option>
            </select>
          </Header>
          <Main>
            <Switch>
              <Route path='/lov-1' component={Law} />
            </Switch>
          </Main>
          <Footer>
            <p>Lovfakta A/S</p>
          </Footer>
        </LandingPageGrid>
      </>
    );
  }
}

export default withRouter(App);
