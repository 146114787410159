import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { LawFormatter } from './Formatter';

import data from './ReferencerPersonskattelov.json';

const Content = styled.div`
  grid-area: content;
  max-width: 100%;
  height: 100%;
  z-index: 0;
  /* overflow-y: scroll; */
`;

const LawComp = styled.div`
  margin: 1rem 0 1rem 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  z-index: 0;
`;

const Paragraph = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

const Reference = props => {
  let refId = props.id;
  let dataFilter = data.Referencer.Dokument.filter(
    reference => reference.DokumentID === refId
  );
  return (
    <Content>
      {dataFilter[0].DokumentIndhold.Bog.Afsnit.Kapitel.ParagrafGruppe.map(
        paragrafGroup => (
          <LawComp key={Math.random()}>
            <h2 key={Math.random()}>{paragrafGroup.Title}</h2>
            {paragrafGroup.Paragraf.map(paragraph => {
              return (
                <Paragraph key={Math.random()}>
                  <p style={{ fontWeight: '600' }}>{paragraph.Explicatus}</p>
                  {!_.isArray(paragraph.Stk) && (
                    // <p key={Math.random()}>{paragraph.Stk.Exitus.Text}</p>
                    <p>§4 a</p>
                  )}
                  {_.isArray(paragraph.Stk) &&
                    paragraph.Stk.map(stk => {
                      return (
                        <>
                          <p key={Math.random()}>
                            {stk.Explicatus}

                            {_.isArray(stk.Exitus) === true
                              ? stk.Exitus[0].Text
                              : stk.Exitus.Text}
                          </p>
                          {_.isArray(stk.Exitus) === true &&
                            stk.Exitus.slice(1).map(el => (
                              <>
                                <p key={Math.random()}>{el.Explicatus}</p>
                                {el.Reference === undefined
                                  ? el.Text
                                  : LawFormatter(el.Text, el.Reference)}
                              </>
                            ))}
                        </>
                      );
                    })}
                </Paragraph>
              );
            })}
          </LawComp>
        )
      )}
    </Content>
  );
};

export default Reference;
