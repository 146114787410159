import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import data from './Personskattelov1.json';

import { LawFormatter } from './Formatter';
import ParagraphLink from './ParagraphLink';

const LawContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  grid-template-columns: 20rem 1fr 20rem;
  grid-template-rows: 8vh 1fr;
  grid-template-areas:
    'slider slider slider'
    'side1 content side2';

  z-index: 0;
`;

const Content = styled.div`
  grid-area: content;
  max-width: 100%;
  height: 100%;
  z-index: 0;
  background: white;
  padding: 0 12rem 0 12rem;
`;

const LawComp = styled.div`
  margin: 1rem 0 1rem 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 0;
`;

const Paragraph = styled.article`
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  z-index: 0;
`;

const ParagraphSliderContainer = styled.div`
  grid-area: slider;
  position: sticky;
  top: 0;
  width: 100%;
  height: 8vh;
  background: #333a42;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24rem 0 24rem;

  h2 {
    margin: 0;
    color: #fbfbfb;
  }

  a {
    color: #6d7174;
    margin: 0;
    font-size: 1.6rem;
  }
`;

const Law = () => {
  const [state, setState] = useState(1);
  console.log(state);
  return (
    <LawContainer>
      <ParagraphSliderContainer onClick={e => setState(e.target.id)}>
        <h2>§</h2>
        <ParagraphLink href='#§ 1.' id='1' selected={state} />
        <ParagraphLink href='#§ 2.' id='2' selected={state} />
        <ParagraphLink href='#§ 3.' id='3' selected={state} />
        <ParagraphLink href='#§ 4.' id='4' selected={state} />
        <ParagraphLink href='#§ 4 a.' id='4 a' selected={state} />
        <ParagraphLink href='#§ 4 b.' id='4 b' selected={state} />
        <ParagraphLink href='#§ 5.' id='5' selected={state} />
        <ParagraphLink href='#§ 6.' id='6' selected={state} />
        <ParagraphLink href='#§ 6 a.' id='6 a' selected={state} />
        <ParagraphLink href='#§ 7 a.' id='7 a' selected={state} />
        <ParagraphLink href='#§ 8 a.' id='8 a' selected={state} />
        <ParagraphLink href='#§ 8 b.' id='8 b' selected={state} />
        <ParagraphLink href='#§ 8 c.' id='8 c' selected={state} />
        {/*
          <p>2</p>
        <p
          style={{
            borderRadius: '50%',
            background: '#F14E17',
            width: '3rem',
            color: 'white',
            textAlign: 'center'
          }}
        >
          3
        </p>
        */}
      </ParagraphSliderContainer>
      <Content>
        {data.Dokument.DokumentIndhold.Bog.Afsnit.Kapitel.ParagrafGruppe.map(
          paragrafGroup => (
            <LawComp key={Math.random()}>
              <h2 key={Math.random()}>{paragrafGroup.Title}</h2>
              {paragrafGroup.Paragraf.map((paragraph, i) => {
                return (
                  <>
                    <div
                      id={paragraph.Explicatus}
                      style={{ width: '1rem', height: '5rem' }}
                    />
                    <Paragraph key={Math.random()}>
                      <p style={{ fontWeight: '600' }}>
                        {paragraph.Explicatus}
                      </p>
                      {!_.isArray(paragraph.Stk) && (
                        <p key={Math.random()}>{paragraph.Stk.Exitus.Text}</p>
                      )}
                      {_.isArray(paragraph.Stk) &&
                        paragraph.Stk.map(stk => {
                          return (
                            <>
                              <p key={Math.random()}>
                                {stk.Explicatus}

                                {_.isArray(stk.Exitus) === true
                                  ? stk.Exitus[0].Text
                                  : stk.Exitus.Text}
                              </p>
                              {_.isArray(stk.Exitus) === true &&
                                stk.Exitus.slice(1).map(el => (
                                  <>
                                    <p key={Math.random()}>{el.Explicatus}</p>
                                    {el.Reference === undefined ? (
                                      <p>{el.Text}</p>
                                    ) : (
                                      LawFormatter(el.Text, el.Reference)
                                    )}
                                  </>
                                ))}
                            </>
                          );
                        })}
                    </Paragraph>
                  </>
                );
              })}
            </LawComp>
          )
        )}
      </Content>
    </LawContainer>
  );
};

export default withRouter(Law);
