import React from 'react';

const ParagraphLink = props => {
  return props.id !== props.selected ? (
    <a
      style={{ color: '#6d7174', margin: '0', fontSize: '1.6rem' }}
      href={props.href}
      id={props.id}
    >
      {props.id}
    </a>
  ) : (
    <a
      style={{
        borderRadius: '50%',
        background: '#F14E17',
        width: '3rem',
        color: 'white',
        textAlign: 'center'
      }}
      href={props.href}
      id={props.id}
    >
      {props.id}
    </a>
  );
};

export default ParagraphLink;
