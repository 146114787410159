import React, { useState } from 'react';
import _ from 'lodash';
import Toggle from './Toggle';
import Modal from './Modal/Modal';

import Reference from './Reference';

export function LawFormatter(text, reference) {
  const [state, setState] = useState(false);
  const ref = reference;

  let textWithOutRef = text;
  let beforeRef = '';
  let withRef = '';
  let afterRef = '';

  let beforeRefArr = [];
  let refArr = [];
  let refIds = [];

  if (_.isArray(reference) === false) {
    let endPos = parseInt(_.values(ref)[2]);
    let startPos = parseInt(_.values(ref)[1]);
    let refId = _.values(ref)[3];
    refIds.push(refId);

    beforeRefArr.push(textWithOutRef.substring(0, startPos));
    refArr.push(textWithOutRef.substring(startPos, endPos));
    afterRef = textWithOutRef.substring(endPos, textWithOutRef.length);
  } else {
    let offset = 0;
    let restText = textWithOutRef;
    ref.map((el, i) => {
      let endPos = parseInt(_.values(el)[2]);
      let startPos = parseInt(_.values(el)[1]);
      let refId = _.values(el)[3];
      refIds.push(refId);

      beforeRef = restText.substring(0, startPos - offset);
      restText = restText.substring(startPos - offset, restText.length);
      offset = startPos;

      withRef = restText.substring(0, endPos - offset);
      restText = restText.substring(endPos - offset, restText.length);
      offset = endPos;

      beforeRefArr.push(beforeRef);
      refArr.push(withRef);

      offset = endPos;
      afterRef = restText;
    });
  }

  return (
    <section
      key={Math.random()}
      style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}
    >
      {beforeRefArr.map((el, index) => {
        return (
          <Toggle>
            {({ on, toggle }) => (
              <>
                <p style={{ margin: '0' }}>{el}</p>
                <p
                  key={Math.random()}
                  href={refIds[index]}
                  style={{
                    color: 'blue',
                    zIndex: '0',
                    margin: '0 0.5rem 0 0.5rem'
                  }}
                  onClick={toggle}
                >
                  {refArr[index]}
                </p>
                {on && (
                  <Modal toggle={toggle} on={on}>
                    <div
                      style={{
                        padding: '1rem 1rem 0rem 1rem',
                        width: '100%',
                        height: '100%',
                        background: 'white',
                        margin: '0',
                        overflowY: 'scroll'
                      }}
                    >
                      <button onClick={toggle}>Close</button>
                      <Reference id={refIds[index]} />
                    </div>
                  </Modal>
                )}
              </>
            )}
          </Toggle>
        );
      })}
      <p style={{ margin: '0' }}>{afterRef}</p>
    </section>
  );
}

// {
// state === true && (
//   <div
//     style={{
//       position: "absolute",
//       left: "50%",
//       top: "-10rem",
//       background: "yellow",
//       width: "20rem",
//       height: "20rem",
//       zIndex: "500"
//     }}
//     onMouseLeave={() => setState(false)}
//   >
//     <p>index: {index}</p>
//   </div>
// )
// }
